.popup {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s, opacity 0.4s linear;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 5;
}
