.header {
    display: flex;
    max-width: 882px;
    /* height: 74px; */
    margin: 0px auto 40px;
    padding: 45px 0 45px 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #545454;
}

@media screen and (max-width:768px) {
    .header {
        margin: 28px auto;
    }
}
