.popup_title {
    font-size: 24px;
    font-weight: 900;
    margin-top: 34px;
    margin-bottom: 54px;
}

@media screen and (max-width: 768px) {
    .popup_title {
        font-size: 18px;
        margin: 25px auto 75px;
    }
}