.reg-log__button {
    margin-top: 150px;
    width: 358px;
    height: 50px;
    text-align: center;
    border-radius: 2px;
    background-color: white;
    border: none;
    outline: none;
    /* justify-self: flex-end; */
}
