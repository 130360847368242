.profile__job {
    font-size: 18px;
    margin: 16px 0 0 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

 @media screen and (max-width: 619px) {
    .profile__job {
         margin: 14px auto 0;
         font-size: 14px;
         font-weight: 400;
     } 
 }  