.header__logo {
    width: 142px;
    height: 33px;
    /* margin-bottom: 40px; */
}

@media screen and (max-width:768px) {
    .header__logo {
        width: 104px;
        height: 24px;
        margin: 0 8px 0 0;
    }
}
