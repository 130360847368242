.profile__button-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    margin: 0 0 0 auto;
    padding: 0px;
    border: 1px solid white;
    border-radius: 2px;
    outline: none;
    font-size: 30px;
    background-color: black;
    text-align: center;
    color: white;
}

.profile__button-add:hover {
    opacity: 0.6;
    transition: opacity 1s;
}

@media screen and (max-width:619px) {
    .profile__button-add {
        margin: 36px 0 0 0;
        /* width: 100%; */
        width: 282px;
    }
}
