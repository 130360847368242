.popup__button-save {
    height: 50px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    outline: none;
    border: none;
    margin-top: 18px;
    margin-bottom: 37px;
}

.popup__button-save:hover {
    opacity: 0.8;
    transition: opacity 1s;
}

@media screen and (max-width:768px) {
   .popup__button-save{ 
       height: 46px;
       margin-bottom: 25px;
   }
}