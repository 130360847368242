.profile__name {
    font-size: 42px;
    font-weight: 500;
    margin: 0 17px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;   
}

@media screen and (max-width:619px) {
    .profile__name {
        font-size: 27px;
    }
}