.footer__text {
    color: #545454;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width:768px) {
    .footer__text {
        font-size: 14px;
    }
}