.reg-log {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    margin: 0 auto;
}

.demo-notice {
    color: lightgray;
}
