.elements__trash {
    position: absolute;
    background-color: transparent;
    border: none;
    outline: none;
    width: 18px;
    height: 19px;
    top: 18px;
    right: 15px;
    background-image: url(../../../images/Trash.svg);  
}

.elements__trash:hover {
    opacity: 0.3;
    transition: opacity 1s;
}
