.profile {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;
    max-width: 882px;
}

@media screen and (max-width:619px) {
    .profile {
        flex-direction: column;
        margin-top: 0;
    }
}