.infotooltip {
    max-width: 80%;
    border: 1px solid peru;
    width: 430px;
    height: 330px;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
}
