.profile__avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 0 30px 0 0;
    overflow: hidden;
    position: relative;
    background-image: url('../../../images/user-avatar.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

 @media screen and (max-width:619px) {
    .profile__avatar {
        margin: 42px 0 0 0;
        align-self: center;
        
    }
}  